.web-site-statistic {


    .month-item {
        width: 400px;
        height: auto;
        border-radius: 20px;
        background-color: whitesmoke;
        padding: 10px;
        background-color: var(--body-background);
        color: var(--body-color);
        border: 1px solid black;
        margin-top: 10px;
        margin-bottom: 10px;

        h3 {
            text-align: center;
        }

        .info {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
        .title {
            text-align: center;
        }
        // background-color: red;
       }
    .web-inner {

font-size: 20px;

        &-menu {

        }

        &-body {
            display: flex;
           flex-wrap: wrap;
           gap: 20px;
          



           .month-item {
            width: 400px;
            height: auto;
            border-radius: 20px;
            background-color: whitesmoke;
            padding: 10px;
            background-color: var(--body-background);
            color: var(--body-color);
            border: 1px solid black;

            h3 {
                text-align: center;
            }

            .info {
                display: flex;
                flex-direction: column;
                gap: 10px;
            }
            // background-color: red;
           }
        }
    }
}