.active__users-list{
    position: absolute;
    z-index: 9999999999;
    background-color: rgba(123,123,123,0.7);
    color: var(--body-color);
   padding: 0.4rem;
   border-radius: 10px;
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 10px;
}