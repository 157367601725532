.emails_send {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    color: var(--body-color);
    position: relative;
}




.greeting__list{
   
    &-file {
        display: flex;
        align-items: center;
        text-align: center;
        gap: 40px;
        margin-top: 20px;

        &.active {
            background-color: green;
        }
        span {
            width: 40%;
        }
        button {
            width: 10%;
        }
    }
}


.start_sending {
    margin-top: 20px;
    h2 {
        text-align: center;
    }

    &-form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 50%;


        textarea {
            resize: none;
            
        }

        button {
            width: 10%;
        }
     
    }
}



.final_sending {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    p {
        font-size: 20px;
    }
    button {
        background-color: green;
    }
}


.sending__id{
    position: relative;
}

.stop__controll{
    position: absolute;
    top: 0;
    right: 0;
}