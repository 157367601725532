.zap__menu {
  position: absolute;
  right: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  transition: all 0.4s ease;



  &:hover {
    background-color: var(--button-main);
    border-radius: 50%;
    transition: all 0.4s ease;
  }
}
.expanded__menu{
  display: flex;
  width: 100%;
  // background-color: red;
  .zap__menu-buttons {
    // position:absolute;
    z-index: 99999;
    top: -40px;
    // right: -160px;
    right: 50px;
    background-color: rgb(230, 235, 203);
    width: 100%;
  
    gap: 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 10px;
    padding: 0.4rem;
    font-size: 13px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
      cursor: pointer;
  }
}


.zap__edit-block {
  position: relative;
  z-index: 999999;
  display: flex;
  gap: 5px;
  align-items: center;
  text-align: center;
  padding: 0.1rem;
  width: 100%;
  transition: all 0.3s ease;
  &:hover {
    background-color: var(--button-main);
    width: 100%;
    border-radius: 10px;
    padding: 0.1rem;
    color: white;
    transition: all 0.3s ease;
  }
}
.edit__zap {
  position: absolute;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: red;
  top: 0;
  left: 0;
}
// .zap__edit-delete {
//   //   background-color: red;
//   width: 100%;
//   padding: 0.4rem;
//   border-radius: 10px;
// }
// .zap__edit-edit {
//   //   background-color: grey;
//   width: 100%;
//   padding: 0.4rem;
//   border-radius: 10px;
// }
