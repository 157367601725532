.header__menu {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.menu__item {
  position: relative;
  padding: 0.2rem;
  background-color: transparent;
  border: 1px solid var(--button-main);
  border-radius: 4px;
  cursor: pointer !important;
  color: var(--body-color);
  transition: all 0.4s ease;
}
.menu__item:hover {
  color: var(--color-white);
  background-color: var(--button-main);
  transition: all 0.4s ease;
}

.submenu__items {
  display: flex;
  gap: 1px;
  text-align: center;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 999;
  top: 45px;
  display: none;
  flex-direction: column;
  background-color: white;
  box-shadow: 5px 5px 5px var(--button-main);
  border-radius: 4px;
  width: 100%;
}

.menu__item:hover > .submenu__items {
  display: flex;
}

.submenu__item {
  background-color: rgb(154, 187, 231);
  border-radius: 4px;
  width: 100%;
  padding: 0.4rem;
  color: var(--color-black);
}
.submenu__item:hover {
  background-color: var(--button-main);
  border-radius: 4px;
}

.submenu::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  background-color: var(--button-main);
  top: 0;
  left: 0;
  transition: all 0.4s ease;
}
.submenu::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  background-color: var(--button-main);
  top: 3px;
  left: 0;
  transition: all 0.4s ease;
}


@media screen and (max-width: 600px) {
  .header {
    width: 100%;

      &__menu {
        background-color: var(--burger-menu-bg);
        color: var(--burger-menu-color);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: absolute;
        height: 100vh;
        width: 100%;
        top: 10vh;
        left: -100%;
        z-index: 99999999;
        &.active {
          // background-color: red;
        }
      }
      &__menu.active {
        top: 10vh;
        left: 0;
        color: var(--burger-menu-color);
      }
   
    }
    .menu__item {
      color: var(--burger-menu-color);
    }
    
    .logistic__work-nav {
      display: flex;
      flex-direction: column;
      gap: 10px;

    }
  }