// KEYFRAMES
.map__block{
  display: flex;
  align-items: center;
  text-align: center;
  gap: 10px;
  cursor: pointer;



}
.zap__comments {
  position: fixed;
  width: 400px;
  height: 80vh;
  top: 0;
  right: -600px;
  background-color: rgb(238, 231, 231);
  padding: 0.7rem;
  border-radius: 10px;
  z-index: 99999;
  animation: shown 0.4s ease;
  display: none;
}
.zap__comments-show {
  position: relative;
  position: fixed;
  width: 500px;
  height: 100vh;
  top: 0;
  right: 0;
  background-color: rgb(242, 241, 241);
  // background-color: var(--background-color);
  padding: 0.7rem;
  border-radius: 6px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  animation: shown 0.4s ease;
  display: block;
  z-index: 99999;
  // overflow-y: scroll;
  .exit__modal {
    position: absolute;
    // width: 100px;
    right: 0;
    top: 0;
    font-size: 30px;
    cursor: pointer;
    // background-color: red;
    z-index: 999;
  }
}
// .form__control{
//   color: var(--body-color);
// }
.carriers__items{
  color: var(--body-color);
}

.zap__delete-block{
  position: absolute;
  width: 100%;
  height: 100vh;
}
.comments__item {
  position: relative;

  &-author {
    padding: 0.1rem;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  &-zap-info {
    .cities {
      font-size: 17px;
      font-weight: bold;
    }
    .zap-text {
      background-color: rgb(214, 208, 208);
      margin-top: 10px;
      font-size: 19px;
      font-weight: bold;
      word-break: break-all;
      font-family: monospace;
      color: black;
      padding: 0.4rem;
      border-radius: 0.4rem;
      height: 10vh;
      overflow-y: scroll;
    }
  }

  &-block {
    margin-top: 20px;
    overflow: hidden;
    overflow-y: scroll;
    min-height: 20vh;
    max-height: 40vh;


   
   

    &::-webkit-scrollbar {
      // display: none;
      width: 5px;
      height: 8px;
      background-color: #aaa;
    }
    &::-webkit-scrollbar-track {
      background: rgb(237, 230, 230);
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }

    .manager__comment {
      display: flex;
      flex-direction: column;
      gap: 10px;

      position: relative;
      border-radius: 10px;
      padding: 0.4rem;
      word-wrap: break-word;
      background-color: rgb(167, 216, 228);

      &-leftside {
        // background-color: red;
        display: flex;
        flex-direction: row;
        gap: 10px;
        text-align: center;
        align-items: center;
        background-color: rgb(98, 195, 228);
        // padding: 0.4rem;
        border-radius: 0.4rem;
      }
      .comment__date {
        // position: absolute;
        //  bottom: 0;
        font-size: 12px;
        color: rgb(29, 28, 28);
      }
      .comment__author {
        font-weight: bold;
        // color: light;
        font-size: 14px;
      }

      &-rightside {
        // background-color: red;
        padding: 0.4rem;
        font-size: 14px;
        font-weight: bold;
      }
    }
    .own {
      // background-color: rgb(201, 140, 116);
      background-color: #bc6ee6;
      // text-decoration: underline;
      > .manager__comment-leftside {
        // background-color: rgb(40, 160, 190);
        background-color: transparent;
      }
      > .manager__comment-rightside {
        // background-color: rgb(21, 59, 184);
        border-radius: 0.4rem;
      }
    }
  }
  &-create {
    // position: absolute;
    // position: absolute;
    bottom: 1px;
    left: 0px;
    // display: flex;
  }
}

.comments__item-block {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: auto;

}

.comments__item-create {
  width: 100%;
  position: absolute;
  bottom: 30px;

}
.comments__control {
  // position: absolute;
  width: 100%;
  align-items: center;
  justify-content: center;
  // background-color: red;
  height: 10vh;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  text-align: center;
  &-button {
    font-size: 30px;
  }
  input {
    width: 100%;
    border: none;
    outline: none;
    padding: 0.8rem;
    border-radius: 10px;
  }
  svg {
    cursor: pointer;
    fill: var(--button-main);
    transition: all 0.4s ease;
  }
  &-send {
    width: 60px;
    height: 30px;
    padding: 0.4rem;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    background-color: rgb(181, 183, 184);
    transition: all 0.4s ease;
  }
  &-send:hover {
    width: 60px;
    height: 30px;
    background-color: rgb(35, 107, 119);
    padding: 0.4rem;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    transition: all 0.3s ease;
  }
  &-send:hover svg {
    transition: all 0.4s ease;
    fill: white;
  }
  // justify-content: space-between;
  // gap: 20px;
}

// KEYFRAMES
// @keyframes shown {
//   0% {
//     right: -600px;
//   }

//   100% {
//     right: 0px;
//   }
// }
.comments__edit {
  position: absolute;
  right: 0;
  top: 10%;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.comment__edit-buttons {
  position: absolute;
  right: 40px;
  top: -10px;
  z-index: 999999;

  > button {
    top: 20px;
    left: 10px;
    text-decoration: none;
  }
}
.no__comments {
  width: 30%;
  // height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 200px;
}
.zap-text-copy{
  position: absolute;
  right: 40px;
  cursor: pointer!important;
  svg {
    fill: rgb(24, 52, 56);
  }
}
.date_create-update{
  display: flex;
  flex-direction: column;
font-style: italic;
text-align: left;
flex-grow: unset;
gap: 5px;

  .created_at{
    color: rgb(34, 33, 33);
  }
  .updated_at{
    color: rgb(6, 109, 2);
    margin-bottom: 5px;
  }
svg {
  width: 20px;
  height: 20px;
}
}
@media screen and (max-width: 600px) {  

  .zap__comments-show {
    position: fixed;
    top: 0;
    right: 0;
    width: 95%;
    height: 100vh;
  }
  .disabled__comments {
    svg {
      width: 30px;
    }
  }
  .comments__item-block{
    // height: 40vh;
    height: auto;
  }
  .map__block{
    display: none;
    align-items: center;
    text-align: center;
    gap: 10px;
    cursor: pointer;
  
  }
}