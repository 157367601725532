/* Regular */
@font-face {
    font-family: benzin;
    src: url('../assets/fonts/Benzin-Regular.ttf') format('truetype');
    font-weight: normal;
}
/* Regular */
@font-face {
    font-family: vis;
    src: url('../assets/fonts/VisueltPro-Regular.ttf') format('truetype');
    font-weight: normal;
}