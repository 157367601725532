.company__files {
  margin-top: 20px;
  display: flex;
flex-direction: column;
margin-top: 20px;
color: var(--body-color);
display: flex;
  .ict__docs {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0 auto;
  }
  .docs__block {
    display: flex;
    gap: 10px;
  }
  h3 {
    color: var(--body-color);
  }
  .document__link-container {
    color: var(--body-color);
    width: 100px;
    height: 100px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: all 0.4s ease;
    padding: 0.4rem;
    &:hover {
      transform: scale(1.1);
      transition: all 0.4s ease;
    }
  }
  .ict__bank{
    display: flex;
    color: var(--body-color);
    p {
      max-width: 300px;
    }

    .bank__info {
      background-color: var(--glass);/* White with 50% opacity */
  border-radius: 8px; /* Optional: Add rounded corners for a smoother look */
  padding: 20px;
  width: 300px;
  margin: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  cursor: context-menu;
    }
  }
.certificates{
  display: flex;
  flex-direction: column;

  &__items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  }
}

}

@media screen and (max-width: 900px) {
.company__files {
  display: flex;
  flex-direction: column;
}
.certificates{
  display: flex;
  flex-direction: column;

  &__items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  }
}

.ict__docs {
  display: flex;
flex-wrap: wrap;
flex-direction: row;
}
.docs__block {
  flex-direction: column;
}

.ict__bank {
  display: flex;
  flex-direction: column;
}
}
@media screen and (max-width: 600px) {
.company__files {
  display: flex;
  flex-direction: column;
}

.certificates{
  display: flex;
  flex-direction: column;

  &__items {
    display: grid;
    grid-template-columns: repeat(1fr, 1fr);
  gap: 40px;
  }
}
.ict__docs {
  flex-wrap: wrap;
  flex-direction: row;
}
.docs__block {
  flex-direction: column;
}

.ict__bank {
  display: flex;
  flex-direction: column;
}
}


@media screen and (max-width: 600px) {
  .certificates{
    display: flex;
    flex-direction: column;
  
    &__items {
      display: flex;
    flex-wrap: wrap;

    }
  }
}