.printers{
    margin-top: 20px;
    color: var(--body-color);
    display: flex;
}

.printer{
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
    // justify-content: space-between;
    // background-color: rgba(255,255,255,0.1);
    div {
        width: 200px;
        // display: flex;
        // text-align: start;
    //    background-color: red;
        // background-color: blue;
        margin-left: 20px;
    }
}

.printer__model {
    padding: 0.4rem;
    margin-bottom: 3px;
    display: flex;
    gap: 10px;
    align-items: center;
    text-align: center;
    width: 100%;
    div {
        display: flex;
        gap: 5px;
  
 
    }
}


.cart_change-title{
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;

    div {
        width: 200px;

   
        margin-left: 20px;
    }
}



.printer__change-cart{
    display: flex;
    flex-direction: row;
    text-align: left;
    // gap: 10px;
    width: 100%;
    height: 30px;
    border: 1px solid var(--body-color);
    gap: 10px;
    margin-top: 20px;

div {
    width: 200px;
    // margin-left: 20px;
    // border: 1px solid whitesmoke;
    // display: flex;
    // text-align: left;
    // word-break: break-all;
    // border: 1px solid rgb(189, 83, 83);
  
}
}




.add_change_cart{
    display: flex;
    flex-direction: row;
    gap: 20px;



    input {
        width: 100%;
        height: 100%;
    }

    select {
        option {
            width: 100%;
            height: 100%;
        }
    }
  
    
}