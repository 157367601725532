.notifications__panel {
    color: var(--notification-color);
    position: absolute;
    width: 300px;
    height: 90vh;
    text-align: left;
    display: flex;
    flex-direction: column;
    top: 10vh;
    right: 100px;
    z-index: 99;
    background-color:var(--notification-background);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    overflow: hidden;
    overflow-y: scroll;
 
    &-button-filters{
      display: flex;
      justify-content: space-between;
      padding: 0.4rem;
      button {
        color: var(--notification-color);
      }
    
    }
&-search {
  color: var(--notification-color);
  background-color: var(--background-color);
  padding: 0.4rem;
  outline: none;
  border: none;
  border-bottom: 1px solid var(--notification-color);
  &::placeholder {
    color: var(--notification-color);
  }
}
    /* width */
    &::-webkit-scrollbar {
        width: 10px;
       
      }
      
      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        max-width: 10px;
        width: 10px;
        
      }
      
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: var(--button-main);
        max-width: 10px;
        width: 10px;
        border-radius: 10px;
      }
      
      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
        
      }
}

.notification__item {
    cursor: context-menu;
    display: flex;
    gap: 10px;
    background-color: var(--notification-item);
    padding: 0.1rem;
    border-bottom: 1px solid black;
    padding: 0.6rem;    
}

.notification {
    &__left{
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    &__author{
     
    }
    &__date{
     font-size: 10px;
    }
}





@media screen and (max-width: 600px) {

  .notifications__panel{
      position: absolute;
      top: 10vh;
      left: 0;
      width: 100%;
      right: 100px;
  }


}