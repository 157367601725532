.admin {
  &__inner {
    margin-top: 20px;
  }
  &__nav {
    display: flex;
    gap: 10px;
  }
  .active__users {
    color: var(--body-color);
    > .user {
      margin-top: 10px;
      display: flex;
      gap: 50px;
      color: var(--body-color);
      padding: 0.4rem;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
      background-color: var(--background-color);
      border: var(--border);
      .user__info {
        width: 100px;
        height: 30px;
      }


    }
  }

  .active__users-block {
    display: flex;
    gap: 10px;
    padding: 0.4rem;
    margin-top: 10px;
    background-color: rgb(27, 199, 12);
    border-radius: 10px;


  }
}
.admin__send-message {
  display: flex;
  flex-direction: column;
  width: 100px;
  textarea {
    height: 50px;
    width: 200px;
    resize: none;
  }
}
.admin__messages-to-users {
  > form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  input {
    height: 40px;
  }
}
.fast__buttons{
  height: 80px;
  display: flex;
  gap: 10px;
  button {
    width: 40%;
    height: 70px;
  }
}

.google__meet-button {
  display: flex;
  gap: 20px;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.all__tg-users{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  color: var(--body-color);
  margin-top: 20px;

  .tg__user{
    background-color: rgb(29, 104, 100);
    padding: 0.4rem;
    border-radius: 10px;
    cursor: pointer;
    &-online {
      background-color: green;
    }
    &-offline {
      background-color: rgb(64, 137, 206);
    }
    &-nach-vid{
      background-color: rgb(151, 43, 84);
    }
  }
}
.tg__users-select {
  margin-top: 20px;
  display: flex;
  gap: 30px;
}

.google__meet-area{
  display: flex;
  flex-direction: row;
}
.google__meet-actions{
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: var(--body-color);
}
.meet__options{
  border: 1px solid var(--body-color);
  padding: 0.4rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  textarea {
    resize: none;
    padding: 0.4rem;
    border-radius: 10px;
  }
}

.show__active-or-inactive{
  display: flex;
  gap: 10px;
}

.admin__nav {
  display: flex;
 flex-wrap: wrap;
}