.google__meet-block{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.google__meet-info{
    background-color: rgba($color: #b1abab, $alpha: 0.7);
    width: 800px;
    height: 400px;
    border-radius: 10px;
    padding: 0.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
    flex-direction: column;

    button {
        height: 100px;
        display: flex;
        flex-direction: column;
        svg {
           font-size: 35px;
        }
    }
}