.logistic {
  position: relative;
  &__work {
    // display: flex;
    // flex-direction: column;
    // margin: 0 auto;
    // width: 100%;
    &-nav {
      width: 70%;
      margin: 0 auto;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
  }
  .zap__groups {
    width: 40%;
  }
}

.zap__count {
  position: absolute;
  right: -10px;
  top: -10px;
  background-color: red;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: var(--body-color);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  z-index: 999;
  font-size: 10px;
}
.zap__count-reg {
  position: absolute;
  right: -10px;
  top: -10px;
  background-color: rgb(39, 140, 235);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  color: var(--body-color);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  z-index: 999;
  font-size: 10px;
}
.zap__count-all {
  position: absolute;
  right: -10px;
  top: -10px;
  background-color: rgb(95, 218, 235);
  border-radius: 1px;
  width: 20px;
  height: 20px;
  color: var(--body-color);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  z-index: 999;
  font-size: 10px;
  color: black;
}
.zap__type {
  text-align: center;
}
.zap__list {
  // display: flex;
  // flex-wrap: wrap;
  // gap: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  margin: 0 auto;
  width: 90%;





}
.zap {
  
  position: relative;
  width: 350px;
  height: auto;
  padding: 0.4rem;
  display: flex;
  gap: 20px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  transition: all 0.2s ease;
  // width: 100px;
  // display: flex;
  // flex-wrap: wrap;
  &:hover {
    cursor: pointer;
    scale: 1.03;
    transition: all 0.2s ease;
  }

  &.color__zakr{
    // background-color: rgb(213, 236, 110);
  }
  &__cities {
    font-size: 13px;
    color: var(--body-color);

    
  }

  &__tztype {
    background-color: orange;
    padding: 1px 2px;
    border-radius: 4px;
    position: absolute;
    top: -14px;
    right: 0px;
    font-size: 13px;
  }
}
.zap__author {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-size: 14px;
  width: 60%;
  &-name {
    color: rgb(212, 69, 69);
  }
  &-time {
    color: rgb(39, 38, 38);
    font-size: 11px;
    cursor: inherit;
  }
}
.zap__text {
  width: 400px;
  word-wrap: break-word;
  height: 100px;
  text-overflow: ellipsis;
  font-size: 14px;
  overflow: auto;
  color: var(--body-color);
  &::-webkit-scrollbar {
    width: 3px;
    background-color: rgb(159, 155, 155);
  }
  &::-webkit-scrollbar-thumb {
    background: #239cd4;
    width: 3px;
  }
}

.zap__comments-length {
  width: 30px;
  height: 30px;
  bottom: 10px;
  right: 60px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  // background-color: red;
  // width: 100px;
  // height: 20px;
  // font-size: 20px;
  font-family: "Courier New", Courier, monospace;
  justify-content: space-between;
}
.zap__comments-counter {
  display: flex;
  width: 100%;
}
.nav {
  position: relative;
  button {
    width: 100%;
  }
}

.comments__tooltip {
  cursor: help;
}

.zap__rules {
  position: absolute;
  top: 0;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  &-block {
    display: flex;
    gap: 10px;
  }
  &-round {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  span {
    color: var(--body-color);
  }
}
.zap__author-time {
  color: var(--body-color);
}
.new__comments {
  background-color: red;
  position: absolute;
  top: -10px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.zap__item-kod {
  font-weight: bold;
  color: var(--body-color);
}

.zap__edit-form {
  position: fixed;
  z-index: 99999;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.zap__search-input {
  color: var(--body-color);
  background-color: var(--background-color);
  font-size: 20px;
  border: 10px solid white;
  // border-bottom: 10px solid green;
  // outline: 10px solid blue;


}
.zap__search-input::placeholder {
  color: var(--body-color);
 
}
.count__car {
  position: absolute;
  bottom: 10px;
  right: 10px;
  span {
    color: var(--body-color);
  }

}
.cities {
  color: rgb(226, 146, 41);
}
@media (max-width: 1350px) {
  .zap__list {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1000px) {
  .zap__list {
    grid-template-columns: repeat(2, 1fr);
  }
  .zap {
    gap: 20px;
  }
  .zap__rules {
    display: none;
  }
}
@media (max-width: 685px) {
  .zap__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
  }
  .zap {
    width: 95%;
    margin: 0 auto;
  }

}


.active__users-length{
  position: absolute;
  top: 30px;
  // right: 50% - 5%;
  right: 0;
  color: var(--body-color);

  // span {
  //   background-color: green;
  //   padding: 0.4rem;
  //   border-radius: 10px;
  //   cursor: context-menu!important;    
  // }
}


.zap__zam{
  position: absolute;
  bottom: -13px;
  left: 0;
  background-color: rgba(110, 209, 226, 0.9);
  width: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: var(--body-color);
  span{
    padding-left: 10px;
  }
}


.pip__filter {
  position: absolute;
  top: 20px;
  right: 200px;
  z-index: 9999999999;
  background-color: lightcyan;
  padding: 0.4rem;
  border-radius: 10px;
  cursor: pointer!important;

}

.price__zap-icon{
  svg {
    fill: var(--price-color);
  }
  
}
.your__zap{
  position: absolute;
  bottom: 10px;
  right: 50px;
  font-size: 20px;
   span {
    color: var(--body-color);
    font-size: 15px;
  }
}
.filter__title {
  color: var(--body-color);
}

.zap__toggle-menu{
  // position: absolute;
  // bottom: 0;
  // right: 0;
}

.zap__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0;

  .expanded__menu{
    width: 90%;
margin-top: 15px;
// background-color: red;

  }


}


