.timer__zap {
    position: absolute;
    top: -70px;
    // background-color: red;
    border: 4px solid rgb(59, 122, 173);
    color: var(--body-color);
    border-radius: 50%;
    padding: 4px;
    z-index: 99999;

    p{
        font-weight: bold;
    }
}