.react__modal {


    &-button {

        background-color: rgb(117, 220, 238);
        outline: none;
        border: none;



   
        
    }

 
}

.react-responsive-modal-modal{
  position: relative;


  .modal__body {
    display: flex;
    flex-direction: column;
    // height:700px;

    .send__button{
        width: 30%;
        margin-top: 20px;
        margin: 0 auto;
        border: 2px solid green;
    }

    
    textarea {
        width: 100%;
        resize: none;
        height: 300px;
        font-size: 20px;
        font-family:'Courier New', Courier, monospace;
        margin-bottom: 40px;
    }
  }
}