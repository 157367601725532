.custom-select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    color: #333;
    transition: all 0.3s ease;
  }
  
  .custom-select:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }
  
  .custom-select option {
    padding: 10px;
  }
  
  .custom-select::-ms-expand {
    display: none; /* Для приховання стрілки у IE */
  }
  
  .custom-select {
    -webkit-appearance: none; /* Для приховання стандартної стрілки в Safari */
    -moz-appearance: none; /* Для приховання стандартної стрілки в Firefox */
    appearance: none; /* Для приховання стандартної стрілки в інших браузерах */
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"%3E%3Cpath d="M14 8l-4 4-4-4" stroke="%23666" stroke-width="2" fill="none"/%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 10px 10px;
  }
  