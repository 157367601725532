.login {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  height: 80vh;
  position: relative;

  &__image {
    position: absolute;
    width: 100%;
    height: 100vh;
    background-position: center;
    background-size: cover;
  }



}
