@import "./styles/index.scss";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body-background);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.main__app{
  position: relative;
}
.go__back {
  position: absolute;
  top: 10px;
  left: 100px;
}

.icon {
  font-size: 30px;
  position: absolute;
  bottom: -30px;
}

#ict {
  position: relative;
}


body::-webkit-scrollbar {
  width: 3px;
}
 
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: var( --button-main);
  outline: 1px solid slategrey;
}

.toasts__close{
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
 z-index: 999999;
 background-color: rgb(218, 85, 85);
}