// .closed__zap {
//   margin: 0 auto;
//   margin-top: 20px;

//   .zap {
//     display: flex;
//     flex-direction: column;

//     &__inner {
//       width: 30%;
//       height: auto;
//     }
//   }
//   &-filters {
//     display: flex;
//     flex-direction: row;
//     gap: 20px;


//     .active {
//       background-color: rgba(228, 117, 132, 0.7);
//     }
//   }
//   &-buttons {
//     display: flex;
//     gap: 20px;
//   }
//   &-managers {
// position: relative;


// .mannager__block{
//   position: absolute;
//   z-index: 9999999999;
//   background-color: rgb(53, 84, 150);
//   display: flex;
//   align-items: center;
//   text-align: center;
//   justify-content: center;
//   // flex-direction: column;
//   flex-wrap: wrap;
//   gap: 20px;
//   top: 40px;
//   padding: 10px;
//   padding: 0.4rem;
//   border-radius: 10px;
//   width:400px;
//   height: auto;

//   .mannagers__filters{
//     display: flex;
//   align-items: center;
//   text-align: center;
//   justify-content: center;
//   flex-wrap: wrap;
//   width: 100%;
//   gap: 20px;

//   .choose__user{
//     display: flex;
//     align-items: center;
//     text-align: center;
//     width: 30%;
//     input {
//       width: 100%;
//       height: 100%;
//     }
//   }
//   }
// }
//   }





//   .closed {
//     width: 100%;
//     display: flex;
//     gap: 40px;
//     flex-wrap: wrap;
//     margin-top: 20px;


//    .actual {
//     background-color: rgba(130, 226, 205,0.4);
//    }
   
//     .delete{
//       background-color: red;
//     }
  
//     .closed_us {
//       background-color: rgba(63, 226, 63, 0.4);
//     }
//   .dont_close_us{
//     background-color: rgb(243, 6, 6,0.4);
//   }
//   .decline_ur{
//     background-color: rgb(187, 95, 95);
//   }
//    .closed_by_ur {
//     background-color: rgba(168, 55, 233, 0.4);
//    }
//    .zap_cina {
//     background-color: rgba(236, 245, 111, 0.4);
//    }



//     .zap{
//       display: flex;
//       width: 30%;
//       height: 100px;
//       // flex-direction:column;
//     }
//   }
// }



// .closed__zap-colors{
//   color: var(--body-color);
// }



// .closed__report{
//   color: var(--body-color);
// }

// // .comments__closed{
// //   display: flex;
// //   flex-direction: column;
// //   position: absolute;
// //   // bottom: -40px;
// //   right:-20px;
// //   background-color: whitesmoke;

// //   .comment_to_closed{
// //     display: flex;
// //   }
// // }
.close {
  color: var(--body-color);
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 20px;
  &__zap{
    // display: flex;
    // flex-wrap: wrap;
    // width: 30%;
    cursor: pointer!important;

    &__inner {
      display: flex;
      gap: 2px;
    }
  }

  &__zap-item {
    background-color: rgba(13, 70, 162, 0.127);
  
  width: 100%;
  display: flex;
  gap: 10px;

  
  }
}
.comments__closed{
  // background-color: rgb(92, 94, 71);
  padding: 0.4rem;
  border-radius: 10px;
}