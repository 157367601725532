.login__form,
.download__form {
  display: flex;
  padding: 2rem;
  flex-direction: column;
  // background-color: var(--body-color);
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.14);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  border: 1px solid rgba(255, 255, 255, 0.9);
  justify-content: center;
  text-align: center;
  align-items: center;
  .form__control {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    margin-top: 20px;
    label {
      color: var(--body-color);
      margin-bottom: 5px;
    }
    input {
      border-radius: 10px;
      outline: none;
      border: none;
      padding: 1rem 0.4rem;

    
    }
  }

  button {
    width: 80%;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .input__eye {
    position: absolute;
    bottom: 10px;
    right: 2px;
    font-size: 20px;
    cursor: pointer;
  }
}
