.tenders {

color: var(--body-color);

    .tenders__inner {
    margin-top: 20px;

.tender__name{
    background-repeat: no-repeat;
    background-size: contain;
    object-fit: cover;
    width: 200px;
    height: 100px;
    cursor: pointer;


}

    .tender__items {

        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 10px;



        .tender__item {
            display: flex;
            flex-direction: row;
            gap: 10px;
            background-color: rgb(38, 119, 88);
            padding: 10px;
            height: 120px;
            width: '33%';

 >div {
    border-right: 1px solid rgb(60, 172, 216);
    padding: 0px 2px;
 }
            .code {
                width: 110px;
            
                word-break: break-all;
            }
            .client{
          
                    width: 120px;
                 
                    word-break: break-all;
         
            }

            .route {
                display: flex;
                flex-direction: column;
            }
        }


      
    }


    }
}