.progress-container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .progress-bar-background {
    width: 100%;
    height: 30px;
    background-color: #e0e0e0;
    border-radius: 5px;
    margin-bottom: 10px;
    overflow: hidden;
  }
  
  .progress-bar-fill {
    height: 100%;
    background-color: #4caf50;
    transition: width 0.3s ease-in-out;
    border-radius: 5px;


  }
  
  .progress-text {
    font-size: 20px;
    color: #9c6464;
  }
  