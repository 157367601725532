.admin__webzap {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    color: var(--body-color);


    .webzap__items {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;


        .webzap-item {
            width: 100%;
            display: flex;
            flex-direction: row;
            gap: 20px;
            margin-top: 20px;
            height: 150px;
            border: 1px solid var(--body-color);
            border-radius: 10px;
            padding: 10px;
            cursor: context-menu;
          

            .date__info {
               width: 120px;
            }
            .contact__info {
                display: flex;             
                flex-direction: column;
                text-align: center;
                align-items: center;
                gap: 30px;
                width: 300px;
                flex: 1;
                
            }

            .zap__info {
                flex: 1;
            }
        }
    }
}



@media screen and (max-width: 600px) {
    .admin__webzap {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        color: var(--body-color);
    
    
        .webzap__items {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 100%;
    
    
            .webzap-item {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 20px;
                margin-top: 20px;
                height: auto;
                overflow: hidden;
                border: 1px solid var(--body-color);
                border-radius: 10px;
                padding: 10px;
              
    
                
                .contact__info {
                    display: flex;             
                    flex-direction: column;
                    text-align: center;
                    align-items: center;
                    gap: 30px;
                    
                }
            }
        }
    }
    
  }