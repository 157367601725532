.workers__list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
  .user {
    padding: 0.4rem;
    border-radius: 4px;
    box-shadow: 5px 5px 15px var(--button-main);
    width: 50%;
    margin: 0 auto;
    color: var(--body-color);
  }
}
.search__input {
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--button-main);
  padding: 0.4rem;
  &.active {
    width: 50%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--button-main);
    padding: 0.4rem;
  }

  input {
    outline: none;
    border: none;
    border-bottom: 1px solid grey;
  }
}



@media (max-width: 685px) {
.search__input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

}
