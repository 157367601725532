.transportation {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  color: var(--body-color);

  &__block {
    display: flex;
    gap: 20px;
  }
  // > div {
  //   display: flex;
  //   gap: 10px;
  //   border-top: 1px solid black;
  //   padding: 0.1rem;
  // }

  &__item {
    width: 100px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  &__title {
    width: 100%;
    display: flex;
    gap: 20px;
    border: 1px solid black;
    border-radius: 10px;
    padding: 0.4rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    &-item {
      width: 100px;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
  }

  &__block {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
}
