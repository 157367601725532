.add__zap {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 10px;
  width: 500px;
  margin: 0 auto;
  margin-bottom: 20px;

  .form__control {
    width: 100%;
    input {
      border: 2px solid var(--button-main);
      outline: none;
      border-radius: 0.4rem;
      padding: 0.4rem;
    }
    textarea {
      border: 2px solid var(--button-main);
      outline: none;
      width: 100%;
      border-radius: 0.4rem;
      padding: 0.4rem;
      height: 100px;
    }
  }
  .form__radio {
    .form__control {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      align-items: center;
      text-align: center;

      input {
        width: 15px;
        height: 15px;
      }
    }
  }
}

.cina__zap {
 color: var(--body-color)
}
.zap-cina {
  // background-color: var(--zap-cina)
}
.zap__cina-icon{
  font-size: 30px;
}
.zam__choose{
  position: relative;
  color: var(--body-color);
  background-color: var(--background-color);
  border-radius: 1px solid var(--body-color);
  background-color: teal;
  padding: 10px;
  border-radius: 0.4rem;

  span {
    position: absolute;
    top: -10px;
    font-size: 20px;
    right: 0;
    cursor: pointer!important;
  }
}
@media screen and (max-width: 600px) { 
.add__zap{
  width: 100%;
  textarea{
    width: 90%;
  }
}
.form__control {
  width: 100%;
  input{
    width: 100%;
    padding: 0rem;
  }

  textarea{
    width: 90%;
    padding: 0rem;
  }
}
}


.per__info {
  display: flex;
 

  
}
.per-control {
  display: flex;
}