.header__burger{
  cursor: pointer;
  display: none;
}
.header__burger-icon{
    font-size: 30px;
    color: var(--body-color);
}

@media screen and (max-width: 600px) {
    .header__burger{ 
        display: block;
    }

}