.worker {
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: center;
  justify-content: center;
  color: var(--body-color);
}
