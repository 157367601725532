.map{
    width: 100%;
    height: 90vh;
    position: relative;

    .control-panel{
        position: absolute;
        top: 0;
        right: 0;
        width: 30%;
        background-color: white;
        max-height: 20vh;
    }

    &__control {
        height: 80px;
    }

    &__info {
        position: absolute;
        top: 0;
        right: 0;
        height: 100vh;
        width: 100%;
        background-color: rgba(0,0,0,0.4);
        display: flex;
        justify-content: center;
        align-items: center;

 
        &-inner {
            position: relative;
            // background-color: white;
            color: white;
            width: 90%;
            height: 70vh;
            border-radius: 10px;
            padding: 20px;
            box-shadow: rgba(0, 0, 0, 0.45) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
            display: grid;
            grid-template-columns: repeat(2,1fr);
            gap: 20px;
          


            .close__btn {
                position: absolute;
                top: 0;
                right: 0;
                cursor: pointer;
                z-index: 9999999;
            }
        
    
            .box{
                width: 100%;
                display: flex;
                align-items: center;
                text-align: center;
                gap: 40px;
                text-align: left;
                &__img {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    border: 2px solid black;
                    padding: 10px;
                    background-color: white;
                    img {
                        width: 100%;
                        height: 100%;
                        height: 30px;
                        object-fit: cover;
                        background-position: center;
                        
                    }
                }
                span {
                    font-weight: bold;
                }
            }


            .map__info-box {
                /* From https://css.glass */

                border:1px solid black;
                border-radius: 20px;
                padding: 20px;
                display: flex;
                flex-direction: column;
                gap: 20px;
                background: rgba(255, 255, 255, 0.23);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);

              
            }
        }
    }
}


@media screen and (max-width:1184px) {
    .map{
        width: 100%;
        height: 90vh;
        position: relative;
    
        .control-panel{
            position: absolute;
            top: 0;
            right: 0;
            width: 30%;
            background-color: white;
            max-height: 20vh;
        }
    
        &__control {
            height: 80px;
        }
    
        &__info {
            position: absolute;
            top: 0;
            right: 0;
            height: 100vh;
            width: 100%;
            background-color: rgba(0,0,0,0.4);
            display: flex;
            justify-content: center;
            align-items: center;
     
            &-inner {

                grid-template-columns: repeat(1,1fr);
                overflow-y: scroll;
    
                .close__btn {
                    position: absolute;
                    top: 0;
                    right: 0;
                    cursor: pointer;
                    z-index: 999999;
                }
            
        
                .box{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    gap: 40px;
                    text-align: left;
                    &__img {
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        border: 2px solid black;
                        padding: 10px;
                        img {
                            width: 100%;
                            height: 100%;
                            height: 30px;
                            object-fit: cover;
                            background-position: center;
                            
                        }
                    }
                    span {
                        font-weight: bold;
                    }
                }
    
    
                .map__info-box {
                    // border:2px solid black;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                  
                }
            }
        }
    }
    
}
@media screen and (max-width:768px) {
    .map{
        width: 100%;
        height: 90vh;
        position: relative;
    
        .control-panel{
            position: absolute;
            top: 0;
            right: 0;
            width: 30%;
            background-color: white;
            max-height: 20vh;
        }
    
        &__control {
            height: 80px;
        }
    
        &__info {
            position: absolute;
            top: 0;
            right: 0;
            height: 100vh;
            width: 100%;
            background-color: rgba(0,0,0,0.4);
            display: flex;
            justify-content: center;
            align-items: center;
     
            &-inner {

                grid-template-columns: repeat(1,1fr);
                overflow-y: scroll;
    
                .close__btn {
                    position: absolute;
                    top: 0;
                    right: 0;
                    cursor: pointer;
                    z-index: 999999;
                }
            
        
                .box{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    gap: 40px;
                    text-align: left;
                    &__img {
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        border: 2px solid black;
                        padding: 10px;
                        img {
                            width: 100%;
                            height: 100%;
                            height: 30px;
                            object-fit: cover;
                            background-position: center;
                            
                        }
                    }
                    span {
                        font-weight: bold;
                    }
                }
    
    
                .map__info-box {
                    // border:2px solid black;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                  
                }
            }
        }
    }
    
}