button {
  position: relative;
  border: none;
  outline: none;
  padding: 0.4rem;
  cursor: pointer;
  transition: all 0.5s ease;
  border-radius: 5px;
}
.normal {
  background-color: transparent;
  border: 1px solid var(--button-main);
  transition: all 0.5s ease;
  color: var(--body-color);

  &:hover {
    background-color: var(--button-main);
    border: 1px solid var(--color-white);
    transition: all 0.5s ease;
    color: var(--body-color);
  }
}
.danger {
  background-color: transparent;
  border: 1px solid var(--button-main);
  color: var(--body-color);
  transition: all 0.5s ease;

  &:hover {
    background-color: rgba(254, 0, 0, 0.4);
    border: 1px solid var(--color-white);
    transition: all 0.5s ease;
    color: var(--body-color);
  }
}

.button__container {
  position: relative;
}

.active-button {
  background-color: var(--button-main);
}
