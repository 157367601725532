.post {
    &__inner {
        margin-top: 20px;
    }




    .new-year-post {
        margin-top: 20px;
    }
    .new-year-text {
        color: var(--body-color);
    }

    .sended__value{
        color: var(--body-color);
    }



    .sended__box{
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        gap: 40px;

        width: 90%;

        div {
            width: 100%;
          
           
        }
    }
    .greeting__email{
        display: flex;
        width: 100%;
        justify-content: space-around;
       margin-top: 20px;
        text-align: left;
        align-items: center;
        text-align: left;
    
       
    }
}