.zap__edit-form {
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
}

.zap__edit_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 30%;

  .form__control {
    width: 100%;
    margin-top: 10px;
  }
  input {
    width: 100%;
  }
  textarea {
    width: 100%;
  }

  &-buttons {
    display: flex;
    gap: 10px;
    margin-top: 20px;

    .normal {
      color: var(--body-color);
      background-color: var(--button-main);
    }
    .danger {
      background-color: red;
      color: white;
    }
  }
}
.radio{
  display: flex;
  flex-direction: row;
  gap: 10px;
  color: var(--body-color);
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
 input {
  width: 15px;
  height: 15px;
  width: 100%;
 }

 &__input {
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
 }
}

.change__zam {
  display: flex;
  gap: 4px;
}
// .selected__radio{
//   background-color: red;
// }